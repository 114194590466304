import React, { forwardRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CustomCheckbox = styled.div`
  line-height: 18px;
  padding: 4px 0;

  &.disabled {
    label {
      opacity: 0.4;
    }
  }
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div.attrs((props) => ({
  border: props.border || 'none',
}))`
  display: inline-block;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  margin: 0 0.7rem 0 0;
  border-radius: 6px;
  transition: all 150ms;
  cursor: pointer;
  border: 2px solid white;

  ${(props) => {
    if (props.disabled) {
      return `
        opacity: .4;
        cursor: initial;
      `
    }
  }}
`

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Checkbox = forwardRef((props, ref) => {
  return (
    <CustomCheckbox className={props.className}>
      <label>
        <CheckboxContainer>
          <HiddenCheckbox
            ref={ref}
            onChange={(_) => {}}
            checked={props.checked}
            data-checked={props.checked}
            style={{
              background: props.checked ? '#000' : '#fff',
            }}
            {...props}
          />
          <StyledCheckbox
            className='styled-chk'
            checked={props.checked}
            disabled={props.disabled}
            {...props}
            onClick={null}
          >
            <Icon
              viewBox='0 0 24 24'
              style={{
                visibility: props.checked ? 'visible' : 'hidden',
              }}
            >
              <polyline points='20 6 9 17 4 12' />
            </Icon>
          </StyledCheckbox>
        </CheckboxContainer>
        <span>{props.labelText}</span>
      </label>
    </CustomCheckbox>
  )
})

Checkbox.defaultProps = {
  className: 'custom-checkbox',
  checked: false,
  labelText: '',
}

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
}

export default Checkbox
