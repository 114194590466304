import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import storageService from '../../services/storageService'
import media from '../../components/css/media'
import useDeviceDetect from '../../hooks/useDeviceDetect'

const MenuSectionUl = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  white-space: nowrap;

  ${media.desktop`
    flex-direction: column;
    overflow: hidden;
  `};
`

const MenuSectionLi = styled.li`
  margin: 0;
  padding: 0 0.5rem;
  list-style: none;
  text-align: center;

  &:first-child {
    padding: 0 0.5rem 0 0;
  }

  ${media.desktop`
    min-width: initial;
    text-align: left;
    padding: 0;
  `};
`

const Href = styled(Link)`
  color: #000;
  padding: 0.8rem 0;
  line-height: 25px;
  text-decoration: none;
  position: relative;
  display: inline-block;

  &:hover,
  &.active {
    text-decoration: none;

    &:after {
      height: 4px;
      width: 100%;
      bottom: 0px;
      left: 0px;
      position: absolute;
      content: '';
      background: rgb(218, 0, 0);
    }
  }

  ${media.desktop`
    height: 100%;
    margin: 0.8rem 0;
    padding: 0;
  `};
`

const storageKeyOfSelectedPageId = 'casino-menu-page-selectedPageId'

const CasinoMenu = ({ menu, currentMenu, slug }) => {
  const intl = useIntl()
  const { isMobile } = useDeviceDetect()

  const onMenuSectionClick = (event, idOfSelectedPage) => {
    /**
     * There is no sense to do this on whatever device that is not mobile
     */
    if (isMobile) {
      storageService.setValue(storageKeyOfSelectedPageId, idOfSelectedPage)
    }
  }

  const idOfSelectedPage = storageService.getValue(storageKeyOfSelectedPageId)

  useEffect(() => {
    return () => {
      const pageListItem = document.getElementById(idOfSelectedPage)

      if (idOfSelectedPage) {
        pageListItem.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        })

        storageService.removeValue(storageKeyOfSelectedPageId)
      }
    }
  })

  return (
    <MenuSectionUl id='casino-menu-list'>
      {menu
        .filter((m) => m.is_visible)
        .map((m, i) => (
          <MenuSectionLi key={i}>
            <Href
              to={`/${intl.locale}/${slug}/${m.slug}/`}
              className={`${
                currentMenu !== undefined && currentMenu.name === m.name
                  ? 'active'
                  : ''
              }`}
              title={`${m.name}`}
              dangerouslySetInnerHTML={{
                __html: m.name,
              }}
              id={`${m.slug}`}
              onClick={(event) => onMenuSectionClick(event, m.slug)}
            />
          </MenuSectionLi>
        ))}
    </MenuSectionUl>
  )
}

export default CasinoMenu
