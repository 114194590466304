import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'

import Container from '../../components/common/container'
import Checkbox from '../../components/common/checkbox'
import Btn from '../../components/common/button'
import media from '../../components/css/media'

import arrow_down_icon from '../../images/arrow_down_icon.svg'
import providers_icon from '../../images/providers_icon.svg'
import close_chips from '../../images/close_chips.svg'

const ProviderFilterWrapper = styled.div`
  background-color: #323232;
  color: #fff;
  line-height: 55px;
  display: none;

  ${media.desktop`
    display: block;
  `};
`

const FiltersWrapper = styled.div`
  display: flex;
`

const SideFilters = styled.div`
  flex: 0 0 20%;
`

const Filters = styled.div`
  background-color: #323232;
  color: #fff;
  line-height: 55px;
  flex: 0 0 80%;
  display: flex;
  flex-wrap: wrap;

  .custom-checkbox {
    flex: 0 1 33.33%;
  }
`

const LabelWrapper = styled.div`
  flex: 0 0 20%;
  cursor: pointer;
`

const Label = styled.label`
  padding-left: 1.8rem;
  padding-bottom: 4px;
  line-height: 25px;
  color: #fff;
  font-weight: 600;
  font-size: 1.1em;
  background: url(${providers_icon}) 0 no-repeat;
`

const ButtonToggle = styled.button`
  width: 20px;
  height: 12px;
  margin: 0 0 0 0.5rem;
  outline: none;
  background: url(${arrow_down_icon}) 50% no-repeat;

  &.active {
    transform: rotate(180deg);
  }
`

const FilterChipsWrapper = styled.div`
  flex: 0 0 80%;
  margin: 0.65rem 0 0 0;
`

const Chip = styled.div`
  background-color: #000;
  color: #fff;
  border-radius: 23.5px;
  line-height: 32px;
  float: left;
  width: auto;
  padding: 0 0.8rem;
  margin: 0 0.5rem 0.5rem 0;
`

const ButtonClose = styled.button`
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: -5px 0 0 1rem;
  vertical-align: middle;
  background: url(${close_chips}) 0 no-repeat;
`

const CasinoFilters = ({ providers, games, setFilters }) => {
  const [toggle, setToggle] = useState(false)
  const [activeItems, setActiveItems] = useState([])
  const refs = useRef(providers.map(() => React.createRef()))

  const changeHandler = (index, disabled, name) => {
    if (disabled) return
    const active = activeItems.filter((i) => i.text === name).length
    const chk = refs.current[index].current
    if (!active) {
      const items = [
        {
          value: chk.dataset.value,
          text: chk.dataset.text,
          index,
        },
      ]
      setActiveItems((actvItems) => {
        if (actvItems.filter((item) => item.index === index).length === 0) {
          return actvItems.concat(items)
        }
        return actvItems
      })
    } else {
      setActiveItems((actvItems) => {
        return actvItems.filter((item) => item.value !== chk.dataset.value)
      })
    }
  }

  const isProviderDisabled = (code) => {
    return games.filter((g) => g.providerCode === code).length === 0
  }

  useEffect(() => {
    setFilters(activeItems)
  }, [activeItems, setFilters])

  const removeChip = (row) => {
    setActiveItems((actvItems) =>
      actvItems.filter((item) => item.text !== row.text)
    )
  }

  if (providers.length === 0) return <div />

  return (
    <ProviderFilterWrapper>
      <Container>
        <FiltersWrapper>
          <SideFilters />
          <Filters>
            <LabelWrapper onClick={() => setToggle(!toggle)}>
              <Label>
                <FormattedMessage id='casino.providers' />
              </Label>
              <ButtonToggle
                type='button'
                className={`${toggle ? 'active' : ''}`}
              />
            </LabelWrapper>
            <FilterChipsWrapper>
              {activeItems.map((row, i) => (
                <Chip key={i}>
                  {row.text}
                  <ButtonClose type='button' onClick={() => removeChip(row)} />
                </Chip>
              ))}
            </FilterChipsWrapper>
          </Filters>
        </FiltersWrapper>
        {toggle && (
          <>
            <FiltersWrapper>
              <SideFilters />
              <Filters>
                {providers.map((p, i) => (
                  <Checkbox
                    className={`custom-checkbox${
                      isProviderDisabled(p.code) ? ' disabled' : ''
                    }`}
                    ref={refs.current[i]}
                    key={i}
                    data-value={`${p.code}`}
                    data-text={`${p.name}`}
                    disabled={isProviderDisabled(p.code)}
                    labelText={`${p.name}`}
                    onClick={() =>
                      changeHandler(i, isProviderDisabled(p.code), p.name)
                    }
                    checked={
                      !!activeItems.filter((i) => i.text === p.name).length
                    }
                  />
                ))}
              </Filters>
            </FiltersWrapper>
            <FiltersWrapper>
              <SideFilters />
              <Btn text='close' onClick={() => setToggle(!toggle)} />
            </FiltersWrapper>
          </>
        )}
      </Container>
    </ProviderFilterWrapper>
  )
}

export default CasinoFilters
