import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from '../../components/common/layout'
import Seo from '../../components/common/seo'
import Container from '../../components/common/container'
import media from '../../components/css/media'
import CasinoMenu from './casinoMenu'
import CasinoFilters from './casinoFilters'
import GamesGrid from './gamesGrid'
import Modal from '../../components/common/modal'
import useModal from '../../hooks/useModal'

import providers_black from '../../images/providers_black.svg'
import success_icon from '../../images/success_icon.svg'
import useDeviceDetect from '../../hooks/useDeviceDetect'

import { getCasinoToken } from '../../adapters/user'
import { AuthContext } from '../../context/authProvider'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-flow: row wrap;
  `};
`

const SideMenu = styled.div`
  ${media.desktop`
    margin: 1rem 0;
    flex-direction: column;
    flex: 0 0 20%;
  `};
`

const ContentArea = styled.div`
  flex: 0 0 100%;
  padding: 1.8rem 0;

  ${media.desktop`
    flex: 0 0 80%;
  `};
`

const CategoryName = styled.div`
  font-weight: 600;
  font-size: 1.2em;
  margin: 0 0 0.6rem 0;

  ${media.desktop`
    font-size: 1.75em;
  `};
`

const GamesFiltersWrapper = styled.div`
  margin: 0 0 1.5rem 0;
`

const MobileFiltersWrapper = styled.div`
  ${media.desktop`
    display: none;
  `};
`

const FilterButton = styled.input.attrs(() => ({
  type: 'button',
}))`
  margin: 1rem 0 0 0 !important;
  padding: 0.2rem 1rem 0.3rem 2.25rem;
  line-height: normal;
  border-radius: 18px;
  text-transform: initial !important;
  color: #000 !important;
  text-align: left;
  width: auto;
  letter-spacing: 0 !important;
  font-weight: 600;
  background: #eee url(${providers_black}) 10px center no-repeat !important;
  border-color: #eee !important;
`

const ModalProviders = styled.div`
  padding: 2rem;
`

const CheckboxWrapper = styled.div`
  font-size: 0.9em;
  display: flex;
  position: relative;
  cursor: pointer;
  border-radius: 6px;
  padding: 1rem;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked ~ label {
      &:before {
        background-color: #fff;
        border: 3px solid #31c27c;
        color: #31c27c;
        background-image: url('${success_icon}');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
      }
    }
  }

  label {
    padding: 0 0 0 2rem;

    &:before {
      display: block;
      position: absolute;
      user-select: none;
      height: 32px;
      width: 32px;
      border-radius: 6px;
      background-color: #eee;
      border: 3px solid #dbdbdb;
      pointer-events: none;
      content: '';
      left: 0;
      top: 0.5rem;
      box-sizing: border-box;
    }

    &:after {
      content: '';
      left: 0;
      top: 0;
      background: no-repeat 50%/50% 50%;
      display: block;
      width: 1rem;
      height: 1rem;
      position: absolute;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.invalid {
    label {
      &:before {
        border: 3px solid #ff8282;
      }
    }
  }
`

const CasinoCategory = ({ pageContext: { currentMenu, type, slug } }) => {
  const [activeProviders, setActiveProviders] = useState([])
  const { openModal, closeModal, isOpen } = useModal()
  const [oryxToken, setOryxToken] = useState(null)
  const { isLoggedIn } = useContext(AuthContext)

  const { isMobile } = useDeviceDetect()
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    {
      casinoMenuApi {
        values {
          lang
          casinoMenu {
            type
            slug
            name
            is_visible
            games {
              desktopSlug
              mobileSlug
              title
              providerCode
              gametags {
                gametag_daily_jackpot
                gametag_hot
                gametag_new
                gametag_power_hour
                gametag_power_week
                gametag_vault_boost
              }
              slug
              thumbnail {
                alt
                url
                sizes {
                  medium
                }
              }
            }
          }
        }
      }
      casinoProvidersApi {
        values {
          lang
          providers {
            code
            name
            vertical
          }
        }
      }
    }
  `)

  const changeHandler = (provider, disabled) => {
    if (disabled) return

    if (
      activeProviders.filter((row) => row.value === provider.code).length === 0
    ) {
      const items = [
        {
          value: provider.code,
          text: provider.name,
        },
      ]
      setActiveProviders(activeProviders.concat(items))
    } else {
      setActiveProviders(
        activeProviders.filter((item) => item.value !== provider.code)
      )
    }
  }

  const handleChkChange = () => {}

  const isProviderDisabled = (code) => {
    return currentMenuGames.filter((g) => g.providerCode === code).length === 0
  }

  const {
    casinoMenuApi: { values: casinoMenu },
    casinoProvidersApi: { values: casinoProviders },
  } = data

  const menuExistForLocale = casinoMenu.some((row) => row.lang === intl.locale)
  const menuExistForPage =
    menuExistForLocale &&
    casinoMenu.filter((row) => row.lang === intl.locale)[0].casinoMenu
  const menu = menuExistForPage
    ? casinoMenu
        .filter((row) => row.lang === intl.locale)[0]
        .casinoMenu.filter((row) => row.type === type)
    : []

  const providersExistForLocale = casinoProviders.some(
    (row) => row.lang === intl.locale
  )
  const providersExistForPage =
    providersExistForLocale &&
    casinoProviders.filter((row) => row.lang === intl.locale)[0].providers
  const providers = providersExistForPage
    ? casinoProviders
        .filter((row) => row.lang === intl.locale)[0]
        .providers.filter((row) => row.vertical === type)
    : []

  const currentMenuGames = menu.some((m) => m.slug === currentMenu.slug)
    ? menu.filter((m) => m.slug === currentMenu.slug)[0].games
    : []

  useEffect(() => {
    const fetchToken = async () => {
      const response = await getCasinoToken()
      if (response.ok) {
        setOryxToken(response.data.data)
      }
    }

    if (isLoggedIn && !oryxToken) {
      fetchToken()
    }
  }, [oryxToken, isLoggedIn])

  return (
    <Layout>
      <Seo title={`${currentMenu.name}`} />
      {!isMobile && (
        <CasinoFilters
          providers={providers}
          games={currentMenuGames}
          setFilters={(activeFilters) => setActiveProviders(activeFilters)}
        />
      )}
      <Container>
        <ContentWrapper>
          <SideMenu>
            <CasinoMenu menu={menu} currentMenu={currentMenu} slug={slug} />
          </SideMenu>
          {providers.length > 0 && (
            <MobileFiltersWrapper>
              <FilterButton
                onClick={openModal}
                value={intl.formatMessage({ id: 'casino.providers' })}
              />
            </MobileFiltersWrapper>
          )}
          <ContentArea>
            {activeProviders.length > 0 &&
              activeProviders.map((a, i) => (
                <GamesFiltersWrapper key={i}>
                  <CategoryName
                    key={i}
                    dangerouslySetInnerHTML={{ __html: a.text }}
                  />
                  <GamesGrid
                    games={currentMenuGames.filter(
                      (game) =>
                        game.providerCode.toLowerCase() ===
                        a.value.toLowerCase()
                    )}
                    currentMenu={currentMenu}
                    oryxToken={oryxToken}
                  />
                </GamesFiltersWrapper>
              ))}
            {activeProviders.length === 0 && (
              <GamesGrid
                games={currentMenuGames}
                currentMenu={currentMenu}
                oryxToken={oryxToken}
              />
            )}
          </ContentArea>
        </ContentWrapper>

        {isOpen && providers.length > 0 && (
          <Modal
            title={intl.formatMessage({ id: 'casino.providers' })}
            isOpen={isOpen}
            onClose={closeModal}
          >
            <ModalProviders>
              {providers.map((p, i) => (
                <CheckboxWrapper
                  key={i}
                  onClick={() => changeHandler(p, isProviderDisabled(p.code))}
                >
                  <input
                    type='checkbox'
                    name={p.code}
                    className={isProviderDisabled(p.code) ? 'disabled' : ''}
                    disabled={isProviderDisabled(p.code)}
                    onChange={handleChkChange}
                    checked={`${
                      activeProviders.filter((row) => row.value === p.code)
                        .length > 0
                        ? 'checked'
                        : ''
                    }`}
                  />
                  <label
                    htmlFor={p.code}
                    className={isProviderDisabled(p.code) ? 'disabled' : ''}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: p.name,
                      }}
                    />
                  </label>
                </CheckboxWrapper>
              ))}

              <input
                type='button'
                value={intl.formatMessage({ id: 'common.show' })}
                onClick={closeModal}
              />
            </ModalProviders>
          </Modal>
        )}
      </Container>
    </Layout>
  )
}

export default CasinoCategory
