import React from 'react'
import styled from 'styled-components'

import media from '../../components/css/media'
import GameTile from './gameTile'

const GamesGridContent = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 0.55rem;
  grid-template-columns: repeat(2, minmax(0, 50%));

  ${media.mobile`
    grid-template-columns: repeat(3, minmax(0, 33.33%));
  `};

  ${media.tablet`
    grid-template-columns: repeat(4, minmax(0, 25%));
  `};
`

const GamesGrid = ({ games, oryxToken }) => {
  return (
    <GamesGridContent>
      {games.map((game, i) => (
        <GameTile key={i} game={game} token={oryxToken} />
      ))}
    </GamesGridContent>
  )
}

export default GamesGrid
